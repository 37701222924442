export const columns = [
  {
    title: '序号',
    key: 'index',
    width: 64,
    scopedSlots: { customRender: 'index' }
  },
  {
    title: '加油站名称',
    key: 'site_name',
    minWidth: 150
  },
  {
    title: '加油机名称',
    key: 'tanker_name',
    minWidth: 80
  },
  {
    title: '监管设备编号',
    key: 'tax_control_num',
    minWidth: 150
  },
  {
    title: '端口号',
    key: 'num',
    minWidth: 100
  },
  {
    title: '枪号',
    key: 'gun_num',
    minWidth: 100
  },
  {
    title: '平台获取编码器号',
    key: 'gain_seal',
    minWidth: 100
  },
  {
    title: '录入编码器号',
    key: 'seal_1',
    minWidth: 100
    // index: 1,
    // scopedSlots: { customRender: 'seal_1' }
  }
  // {
  //   title: '录入编码器号2',
  //   key: 'seal_2',
  //   minWidth: 100,
  //   index: 2,
  //   scopedSlots: { customRender: 'seal_2' }
  // },
  // {
  //   title: '录入编码器号3',
  //   key: 'seal_3',
  //   minWidth: 100,
  //   index: 3,
  //   scopedSlots: { customRender: 'seal_3' }
  // },
  // {
  //   title: '录入编码器号4',
  //   key: 'seal_4',
  //   minWidth: 100,
  //   index: 4,
  //   scopedSlots: { customRender: 'seal_4' }
  // },
  // {
  //   title: '录入编码器号5',
  //   key: 'seal_5',
  //   minWidth: 100,
  //   index: 5,
  //   scopedSlots: { customRender: 'seal_5' }
  // },
  // {
  //   title: '录入编码器号6',
  //   key: 'seal_6',
  //   minWidth: 100,
  //   index: 6,
  //   scopedSlots: { customRender: 'seal_6' }
  // },
  // {
  //   title: '录入编码器号7',
  //   key: 'seal_7',
  //   minWidth: 100,
  //   index: 7,
  //   scopedSlots: { customRender: 'seal_7' }
  // },
  // {
  //   title: '录入编码器号8',
  //   key: 'seal_8',
  //   minWidth: 100,
  //   index: 8,
  //   scopedSlots: { customRender: 'seal_8' }
  // }
];
